import React, { useEffect, useState } from 'react'

const ChevronDown = () => {
    const [width, setWidth] = useState('32');
    const [height, setHeight] = useState('21');
    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth < 640) {
                setWidth('18');
                setHeight('24');
            } else {
                setWidth('32');
                setHeight('21');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <svg width={width} height={height} viewBox="0 0 32 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.83789 1.14264C3.10354 0.316986 1.86467 0.281095 1.08852 1.06865C0.330623 1.8377 0.301761 3.10241 1.01926 3.90911L15.3275 19.9963C15.6855 20.3987 16.3145 20.3987 16.6725 19.9962L30.9807 3.90911C31.6982 3.10241 31.6694 1.8377 30.9115 1.06865C30.1353 0.281095 28.8965 0.316986 28.1621 1.14264L16 14.8168L3.83789 1.14264Z" fill="#F8FAFC" stroke="#F8FAFC" strokeLinecap="round" />
        </svg>
    )
}

export default ChevronDown