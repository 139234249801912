import React, { FC, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'

interface WhatsAppButtonProps {
    title: string
    waLink: string
}

const WhatsAppButton: FC<WhatsAppButtonProps> = ({ title, waLink }) => {
    const [isHovering, setIsHovering] = useState(false);

    const [iconSize, setIconSize] = useState<SizeProp>('3x');
    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth < 640) {
                setIconSize('2x');
            } else {
                setIconSize('3x');

            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };
    const styleBorderRadius = () => {
        if (title === 'Admin 2') {
            return 'rounded-tr-xl rounded-br-xl'
        }
        return 'rounded-tl-xl rounded-bl-xl'
    }

    const styleTitle = () => {
        if (isHovering) {
            return 'text-white text-xs'
        }
        return 'text-secondary-100 text-xs'
    }

    return (
        <a
            href={waLink}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={`flex flex-col items-center py-2 sm:py-4 px-6 sm:px-10 bg-main-100 hover:bg-accent-100 cursor-pointer ${styleBorderRadius()}`}
        >
            <FontAwesomeIcon icon={faWhatsapp} size={iconSize} style={{ color: isHovering ? 'white' : '#1E293B', }} />
            <p className={`font-body font-semibold mt-2 ${styleTitle()}`}>{title}</p>
        </a>
    )
}

export default WhatsAppButton