import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dropdown, MenuProps } from "antd"
import { useRouter } from "next/router";

export const ProgramNavBar: React.FC = () => {

    const router = useRouter();

    const items: MenuProps['items'] = [
        // Because this section not used in new UI
         
        // {
        //     key: 'paket-standard',
        //     label: 'Paket Standard',
        //     children: [
        //         {
        //             key: 'back-end',
        //             label: 'Back-End',
        //             onClick: () => router.push('/program/back-end')
        //         },
        //         {
        //             key: 'front-end',
        //             label: 'Front-End',
        //             onClick: () => router.push('/program/front-end')
        //         },
        //         {
        //             key: 'Front-end-mobile',
        //             label: 'Front-End Mobile',
        //             onClick: () => router.push('/program/front-end-mobile')
        //         },
        //     ]
        // },
        // {
        //     key: 'paket-hemat',
        //     label: 'Paket Hemat',
        //     children: [
        //         {
        //             key: 'fullstack-mobile',
        //             label: 'Fullstack Mobile',
        //             onClick: () => router.push('/program/fullstack-mobile')
        //         },
        //         {
        //             key: 'fullstack-web',
        //             label: 'Fullstack Web',
        //             onClick: () => router.push('/program/fullstack-web')
        //         },
        //     ]
        // },
        // {
        //     key: 'paket-lengkap',
        //     label: 'Paket Lengkap',
        //     children: [
        //         {
        //             key: 'fullstack-web-mobile',
        //             label: 'Fullstack Web & Mobile',
        //             onClick: () => router.push('/program/fullstack-web-mobile')
        //         },
        //     ]
        // },
        {
            key: 'free-course',
            label: 'Free Course: Introduction to IT Programming',
            children: [
                {
                    key: 'video-game',
                    label: 'Video Game',
                    onClick: () => {
                        router.push('/program/free-course/video-game')
                    }
                },
                {
                    key: 'front-end-website',
                    label: 'Front-End Website',
                    onClick: () => {
                        router.push('/program/free-course/front-end-website')
                    }
                },
                {
                    key: 'back-end-website',
                    label: 'Back-End Website',
                    onClick: () => {
                        router.push('/program/free-course/back-end-website')
                    }
                },
                {
                    key: 'mobile-application',
                    label: 'Mobile Application',
                    onClick: () => {
                        router.push('/program/free-course/mobile-application')
                    }
                },
            ]
        },
        {
            key: 'workshop',
            label: 'Workshop: Coding Kickstart',
            children: [
                {
                    key: 'video-game',
                    label: 'Video Game',
                    onClick: () => {
                        router.push('/program/workshop/video-game')
                    }
                },
                {
                    key: 'front-end-website',
                    label: 'Front-End Website',
                    onClick: () => {
                        router.push('/program/workshop/front-end-website')
                    }
                },
                {
                    key: 'back-end-website',
                    label: 'Back-End Website',
                    onClick: () => {
                        router.push('/program/workshop/back-end-website')
                    }
                },
                {
                    key: 'mobile-application',
                    label: 'Mobile Application',
                    onClick: () => {
                        router.push('/program/workshop/mobile-application')
                    }
                },
            ]
        },
        {
            key: 'bootcamp',
            label: 'Bootcamp',
            children: [
                {
                    key: '/program/bootcamp/full-stack-engineer',
                    label: 'Full Stack Engineer',
                    onClick: () => {
                        router.push('/program/bootcamp/full-stack-engineer')
                    }
                },
            ]
        },
    ];

    return (
        <Dropdown
            className="block duration-300  hover:text-accelist-green lg:p-2 lg:mx-4"
            menu={{ items }}>
            <a onClick={(e) => {
                e.preventDefault()
                router.push('/#program-type')
            }

            }>
                Program
                <FontAwesomeIcon icon={faChevronDown} className="ml-2"></FontAwesomeIcon>
            </a>
        </Dropdown>
    )
}