import React, { useState } from 'react'
import Chat from './common/shape/Chat'
import ChevronDown from './common/shape/ChevronDown'
import WhatsAppButton from './common/buttons/WhatsAppButton'

const WhatsAppContactOptions = () => {
    const [isOpen, setIsOpened] = useState(false)
    const adminOne = 'https://wa.me/6281215636018?text=Halo%20Mindu%201%2C%20perkenalkan%20saya%0ANama%20%3A%20%0APertanyaan%20%3A'
    const adminTwo = 'https://wa.me/6285959595915?text=Halo%20Mindu%202%2C%20perkenalkan%20saya%0ANama%20%3A%20%0APertanyaan%20%3A'

    const renderIcon = () => {
        if (isOpen) return (
            <div onClick={() => setIsOpened(false)} className="flex items-center justify-center w-10 h-10 sm:w-16 sm:h-16 bg-accent-100 rounded-full cursor-pointer">
                <ChevronDown />
            </div>
        )
        return (
            <div onClick={() => setIsOpened(true)} className="flex items-center justify-center w-10 h-10 sm:w-16 sm:h-16 bg-accent-100 rounded-full cursor-pointer">
                <Chat />
            </div>
        )
    }

    const renderOptions = () => {
        if (isOpen) {
            return (
                <div className='flex flex-row items-center bg-main-100 rounded-xl mb-5 drop-shadow-md'>
                    <WhatsAppButton title='Admin 1' waLink={adminOne} />
                    <WhatsAppButton title='Admin 2' waLink={adminTwo} />
                </div >
            )
        }
        return null
    }

    return (
        <div className='flex flex-col items-end fixed bottom-32 md:bottom-16 sm:bottom-8 right-5 sm:right-7 z-50'>
            {renderOptions()}
            {renderIcon()}
        </div>
    )
}

export default WhatsAppContactOptions