import { signOut } from "next-auth/react";

/**
 * Logout from web app and SSO.
 */
export async function logout() {
    try {
        signOut({ callbackUrl: '/api/logout' });
    } catch (error) {
        console.log(error);
    }
}