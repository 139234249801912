import React from 'react';
import Head from 'next/head';

export const Title: React.FC<{
    title: string | number
    description: string
}> = ({ title, description }) => {
    return (
        <Head>
            <title key="title">{title.toString() + ' - Accelist Edukasi Indonesia'}</title>
            <meta key="desc" name="description" content={description} />
        </Head>
    );
}
