import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
    const [showButton, setShowButton] = useState(false);
    const [iconSize, setIconSize] = useState<SizeProp>('3x');

    const handleScroll = () => {
        const threshold = 200;
        if (window.scrollY > threshold) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        const handleResize = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth < 640) {
                setIconSize('2x');
            } else {
                setIconSize('3x');

            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div>
            {showButton && (
                <div className='flex flex-col items-end fixed bottom-20 sm:bottom-32 right-5 sm:right-7 z-50'>
                    <div onClick={scrollToTop} className="flex items-center justify-center w-10 h-10 sm:w-16 sm:h-16 bg-accent-100 rounded-full cursor-pointer">
                        <FontAwesomeIcon icon={faChevronUp} size={iconSize} style={{ color: 'white' }} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ScrollToTopButton;