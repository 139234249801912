import React, { useEffect, useState } from 'react'

const Chat = () => {
    const [width, setWidth] = useState('37');
    const [height, setHeight] = useState('38');
    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth < 640) {
                setWidth('27');
                setHeight('28');
            } else {
                setWidth('37');
                setHeight('38');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <svg width={width} height={height} viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M36.7585 16.6595C36.7585 19.8452 35.7265 22.8163 33.9438 25.3222C31.0839 29.6674 23.6832 35.1605 19.7032 37.9655V32.8387C19.3224 32.8599 18.9384 32.8706 18.5516 32.8706C8.49623 32.8706 0.344727 25.6126 0.344727 16.6595C0.344727 7.70628 8.49623 0.448303 18.5516 0.448303C28.607 0.448303 36.7585 7.70628 36.7585 16.6595ZM10.9461 19.9017C12.4735 19.9017 13.7117 18.6575 13.7117 17.1226C13.7117 15.5878 12.4735 14.3436 10.9461 14.3436C9.41874 14.3436 8.18054 15.5878 8.18054 17.1226C8.18054 18.6575 9.41874 19.9017 10.9461 19.9017ZM21.5476 17.1226C21.5476 18.6575 20.3094 19.9017 18.782 19.9017C17.2546 19.9017 16.0164 18.6575 16.0164 17.1226C16.0164 15.5878 17.2546 14.3436 18.782 14.3436C20.3094 14.3436 21.5476 15.5878 21.5476 17.1226ZM26.6178 19.9017C28.1452 19.9017 29.3834 18.6575 29.3834 17.1226C29.3834 15.5878 28.1452 14.3436 26.6178 14.3436C25.0904 14.3436 23.8522 15.5878 23.8522 17.1226C23.8522 18.6575 25.0904 19.9017 26.6178 19.9017Z" fill="#F8FAFC" />
        </svg>
    )
}

export default Chat