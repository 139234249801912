import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, MenuProps } from "antd";
import { signIn, useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { logout } from "@/functions/Logout";
import { ProgramNavBar } from "./ProgramNavBar";

export const NavigationBar: React.FC = () => {

    const { data: session, status } = useSession();
    const displayUserName = session?.user?.name;
    const displayPicture = session?.user?.image ?? '';
    const router = useRouter();

    const items: MenuProps['items'] = [
        {
            key: 'settings',
            label: 'Pengaturan',
            style: { fontSize: "16px", lineHeight: "20px" },
            onClick: () => router.push('/settings')
        },
        {
            key: 'sign-out',
            label: 'Keluar',
            style: { fontSize: "16px", lineHeight: "20px" },
            onClick: () => onLogout()
        }
    ];

    const onLogout = logout;

    const loggedIn = () => {
        if (status === 'authenticated') {
            return (
                <ul className="flex text-accelist-font items-center">
                    <li>
                        <Dropdown
                            className="block duration-300  hover:text-accelist-green lg:p-2 lg:mx-4"
                            menu={{ items }}>
                            <a onClick={(e) => e.preventDefault()} className="flex items-center">
                                <p className="block duration-300  hover:text-accelist-green lg:p-2">Halo, {displayUserName}</p>
                                <img src={displayPicture} alt="" style={{ width: 50 }} className="rounded-full" />
                                <FontAwesomeIcon icon={faChevronDown} className="ml-2"></FontAwesomeIcon>
                            </a>
                        </Dropdown>
                    </li>
                </ul>
            )
        } else {
            return (
                <ul className="flex text-accelist-font items-center">
                    <li className="bg-accelist-green rounded-md hover:bg-black duration-300">
                        <button onClick={() => signIn('auth0')} className="block duration-300 text-white hover:text-white lg:p-2">Login / Sign Up</button>
                    </li>
                </ul>
            )
        }
    }

    return (
        <div className="hidden lg:flex xl:flex 2xl:flex m-4 text-xl justify-between">
            <img src="/img/layouting/logo-accelist.png" alt="Logo" style={{ width: 150 }} ></img>
            <ul className="flex text-accelist-font items-center">
                <li>
                    <Link href={"/"} className="block duration-300 hover:text-accelist-green lg:p-2 lg:mx-4">Home</Link>
                </li>
                <li>
                    <Link href={"/tentang-kami"} className="block duration-300 hover:text-accelist-green lg:p-2 lg:mx-4">Tentang Kami</Link>
                </li>
                <li>
                    {/* <Link href={"/#program-type"} className="block duration-300 hover:text-accelist-green lg:p-2 lg:mx-4">Program</Link> */}
                    <ProgramNavBar />
                </li>
                <li>
                    <Link href={"/lms"} className="block duration-300 hover:text-accelist-green lg:p-2 lg:mx-4">LMS</Link>
                </li>
                <li>
                    <Link href={"/#testimony"} className="block duration-300 hover:text-accelist-green lg:p-2 lg:mx-4">Testimony</Link>
                </li>
                <li>
                    <Link href={"https://accelist.com/"} className="block duration-300 hover:text-accelist-green lg:p-2 lg:mx-4">Accelist Group</Link>
                </li>
            </ul>
            {loggedIn()}
        </div>
    )
}