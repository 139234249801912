import React, { useEffect, useState } from "react";
import Head from 'next/head';
import { Button, ConfigProvider, Drawer, Layout, Menu, MenuProps } from "antd";
import { NavigationBar } from "./NavigationBar";
import Link from "next/link";
import { useRouter } from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { signIn, useSession } from "next-auth/react";
import { logout } from "@/functions/Logout";
import WhatsAppContactOptions from "./WhatsAppContactOptions";
import ScrollToTopButton from "./common/buttons/ScrollToTopButton";
import CookieBanner from "./CookieBanner";
import { getLocalStorage } from '../functions/Helper';
// import { GoogleAnalytics } from "./GoogleAnalytics";

const { Content, Header, Footer } = Layout;

const sidebarBackgroundColor = '#001529';

const DefaultLayout: React.FC<{
    children: React.ReactNode
}> = ({ children }) => {

    const { data: session, status } = useSession();
    const displayUserName = session?.user?.name;
    const displayPicture = session?.user?.image ?? '';
    const router = useRouter();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selected, setSelected] = useState([router.pathname]);
    const [cookieConsent, setCookieConsent] = useState<boolean | null>();

    useEffect(() => {
      const storedCookieConsent = getLocalStorage('cookie_consent', null);
      setCookieConsent(storedCookieConsent);
    }, []);

    const updateCookieConsent = (consent: boolean) => {
        setCookieConsent(consent);
      };

    function renderAvatar() {
        if (status === 'authenticated') {
            return (
                <div className="flex flex-col items-center">
                    <div>
                        <img src={displayPicture} alt="" style={{ width: 50 }} className="rounded-full" />
                    </div>
                    <div className="my-4">
                        Halo, {displayUserName}
                    </div>
                </div>
            );
        }

        return null;
    }

    const onLogout = logout;

    function getMenu(): MenuProps['items'] {
        const menu: MenuProps['items'] = [];

        menu.push({
            key: '/',
            label: 'Home',
            onClick: () => {
                setDrawerOpen(false)
                router.push('/')
            }
        });

        menu.push({
            key: '/tentang-kami',
            label: 'Tentang Kami',
            onClick: () => {
                setDrawerOpen(false)
                router.push('/tentang-kami')
            }
        });

        menu.push(
            {
                key: '/#program-type',
                label: 'Program',
                onClick: () => {
                    setDrawerOpen(false)
                    // Because when the default layout is pressed, the program will enter the program type section
                    // setTimeout(() => {
                    //     router.push('/#program-type')
                    // }, 500)
                },
                children: [
                    {
                        key: '/program/free-course',
                        label: 'Free Course: Introduction to IT Programming',
                        children: [
                            {
                                key: '/program/video-game',
                                label: 'Video Game',
                                onClick: () => {
                                    router.push('/program/free-course/video-game')
                                    setDrawerOpen(false)
                                }
                            },
                            {
                                key: '/program/front-end-website',
                                label: 'Front-End Website',
                                onClick: () => {
                                    router.push('/program/free-course/front-end-website')
                                    setDrawerOpen(false)
                                }
                            },
                            {
                                key: '/program/back-end-website',
                                label: 'Back-End Website',
                                onClick: () => {
                                    router.push('/program/free-course/back-end-website')
                                    setDrawerOpen(false)
                                }
                            },
                            {
                                key: '/program/mobile-application',
                                label: 'Mobile Application',
                                onClick: () => {
                                    router.push('/program/free-course/mobile-application')
                                    setDrawerOpen(false)
                                }
                            },
                        ]
                    },
                    {
                        key: '/program/workshop',
                        label: 'Workshop: Coding Kickstart',
                        children: [
                            {
                                key: '/program/video-game',
                                label: 'Video Game',
                                onClick: () => {
                                    router.push('/program/workshop/video-game')
                                    setDrawerOpen(false)
                                }
                            },
                            {
                                key: '/program/front-end-website',
                                label: 'Front-End Website',
                                onClick: () => {
                                    router.push('/program/workshop/front-end-website')
                                    setDrawerOpen(false)
                                }
                            },
                            {
                                key: '/program/back-end-website',
                                label: 'Back-End Website',
                                onClick: () => {
                                    router.push('/program/workshop/back-end-website')
                                    setDrawerOpen(false)
                                }
                            },
                            {
                                key: '/program/mobile-application',
                                label: 'Mobile Application',
                                onClick: () => {
                                    router.push('/program/workshop/mobile-application')
                                    setDrawerOpen(false)
                                }
                            },
                        ]
                    },
                    {
                        key: '/program/bootcamp/full-stack-engineer',
                        label: 'Bootcamp',
                        children: [
                            {
                                key: '/program/bootcamp/full-stack-engineer',
                                label: 'Full Stack Engineer',
                                onClick: () => {
                                    router.push('/program/bootcamp/full-stack-engineer')
                                    setDrawerOpen(false)
                                }
                            },
                        ]
                    },
                ]
            },
        );

        menu.push({
            key: '/lms',
            label: 'LMS',
            onClick: () => {
                setDrawerOpen(false)
                router.push('/lms')
            }
        });

        menu.push({
            key: '/#testimony',
            label: 'Testimony',
            onClick: () => {
                setDrawerOpen(false)
                setTimeout(() => {
                    router.push('/#testimony')
                }, 500)
            }
        });

        menu.push({
            key: '/accelist-group',
            label: 'Accelist Group',
            onClick: () => router.push('https://accelist.com/'),
        });

        if (status === 'authenticated') {
            menu.push({
                key: '/sign-out',
                label: `Keluar`,
                onClick: () => onLogout()
            });
        } else {
            menu.push({
                key: '/login',
                label: 'Login',
                onClick: () => signIn('auth0')
            });
        }

        return menu;
    }

    return (
        <ConfigProvider theme={{
            components: {
                Layout: {
                    // Sidebar background color:
                    // https://github.com/ant-design/ant-design/blob/5.0.0/components/layout/style/index.tsx#L101
                    colorBgHeader: sidebarBackgroundColor
                }
            }
        }}>
            <Layout className="min-h-screen">
                <Head>
                    <meta key="meta-charset" charSet="utf-8" />
                    <meta key="meta-viewport" name="viewport" content="width=device-width, initial-scale=1" />
                    <link key="favicon" rel="icon" href="/favicon.ico" />
                </Head>
                <Header className="bg-white drop-shadow-lg h-auto w-auto">
                    <NavigationBar />
                </Header>
                <Drawer placement="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                    {renderAvatar()}
                    <ConfigProvider theme={{
                        components: {
                            Menu: {
                                // https://github.com/ant-design/ant-design/blob/5.0.0/components/menu/style/theme.tsx#L194
                                colorActiveBarBorderSize: 0
                            }
                        }
                    }}>
                        <Menu mode="inline" items={getMenu()}
                            selectedKeys={selected} onSelect={e => setSelected(e.selectedKeys)} />
                    </ConfigProvider>
                </Drawer>
                <Layout>
                    <div className='bg-white grid grid-cols-3 lg:hidden xl:hidden 2xl:hidden px-8 py-8 items-center drop-shadow-md'>
                        <div>
                            <Button onClick={() => setDrawerOpen(true)} className="duration-300 bg-accelist-green hover:bg-black border-none">
                                <FontAwesomeIcon icon={faBars} style={{ color: 'white' }}></FontAwesomeIcon>
                            </Button>
                        </div>
                        <img src="/img/layouting/logo-accelist.png" alt="Logo"></img>
                    </div>
                    <Content className="bg-white">
                        {children}
                    </Content>
                </Layout>
                <Footer>
                    <div className="flex flex-wrap justify-between text-accelist-font ml-6">
                        <div className="text-xl">
                            <img src="/img/layouting/logo-accelist.png" alt="Logo" style={{ width: 150 }} className="mb-12"></img>
                            <div className="flex flex-col sm:flex-row">
                                <img
                                    src="/img/layouting/maps.png"
                                    alt="Icon"
                                    style={{ width: 30, height: 30 }}
                                    className="mr-4 sm:mr-0 sm:mb-0"
                                ></img>
                                <div className="text-sm sm:text-lg">
                                    Ruko Bolsena Blok A-19, Curug Sangereng, Kec. Klp. Dua,
                                </div>
                            </div>

                            <div className="text-sm sm:text-lg ml-12">Kabupaten Tangerang, Banten 15810, Indonesia</div>
                            <div className="flex items-center mt-2">
                                <img src="/img/layouting/email.png" alt="Icon" style={{ width: 30 }} className="mr-4"></img>
                                <div className="text-sm sm:text-lg">
                                    marketing@edu.accelist.com
                                </div>
                            </div>
                            <div className="flex items-center mt-2">
                                <img src="/img/layouting/telp.png" alt="Icon" style={{ width: 30 }} className="mr-4"></img>
                                <div className="text-sm sm:text-lg">
                                    62 859-5959-5915
                                </div>
                            </div>
                            <div className="flex items-center mt-2">
                                <img src="/img/layouting/web.png" alt="Icon" style={{ width: 30 }} className="mr-4"></img>
                                <div className="text-sm sm:text-lg">
                                    http://www.accelist.com

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="text-2xl text-accelist-green mt-4">Main Menu</div>
                            <Link href={"/"} className="block duration-300 hover:text-accelist-green my-4">Home</Link>
                            <Link href={"/tentang-kami"} className="block duration-300 hover:text-accelist-green mb-4">Tentang Kami</Link>
                            <Link href={"#testimony"} className="block duration-300 hover:text-accelist-green mb-4">Testimony</Link>
                            <Link href={"https://accelist.com/"} className="block duration-300 hover:text-accelist-green mb-4">Accelist Group</Link>
                        </div>
                        <div>
                            <div className="text-2xl text-accelist-green mt-4">Program</div>

                            <Link href={"/program/free-course/video-game"} className="block duration-300 hover:text-accelist-green my-4">Free Course: Video Game</Link>
                            <Link href={"/program/free-course/front-end-website"} className="block duration-300 hover:text-accelist-green mb-4">Free Course: Front-End Website</Link>
                            <Link href={"/program/free-course/back-end-website"} className="block duration-300 hover:text-accelist-green mb-4">Free Course: Back-End Website</Link>
                            <Link href={"/program/free-course/mobile-application"} className="block duration-300 hover:text-accelist-green mb-4">Free Course: Mobile Application</Link>
                            <Link href={"/program/workshop/video-game"} className="block duration-300 hover:text-accelist-green mb-4">Workshop: Video Game</Link>
                            <Link href={"/program/workshop/front-end-website"} className="block duration-300 hover:text-accelist-green mb-4">Workshop: Front-End Website</Link>
                            <Link href={"/program/workshop/back-end-website"} className="block duration-300 hover:text-accelist-green mb-4">Workshop: Back-End Website</Link>
                            <Link href={"/program/workshop/mobile-application"} className="block duration-300 hover:text-accelist-green mb-4">Workshop: Mobile Application</Link>
                            <Link href={"/program/bootcamp/full-stack-engineer"} className="block duration-300 hover:text-accelist-green mb-4">Bootcamp: Full Stack Engineer</Link>
                        </div>
                        <div className="mr-96 mt-6">
                            <br />
                            <Link href={"/kebijakan-privasi"} className="block duration-300 hover:text-accelist-green my-4">Kebijakan Privasi</Link>
                            <Link href={"/#faq"} className="block duration-300 hover:text-accelist-green mb-4">FAQ</Link>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <a href="https://www.youtube.com/@AccelistEdukasiIndonesia" target="_blank">
                            <img src="/img/layouting/youtube.png" alt="Icon" style={{ width: 40, height: 40 }} className="mr-4"></img>
                        </a>
                        <a href="https://instagram.com/accelistedukasi.indonesia?igshid=MzRlODBiNWFlZA==" target="_blank">
                            <img src="/img/layouting/instagram.png" alt="Icon" style={{ width: 40, height: 40 }} className="mr-4"></img>
                        </a>
                        <a href="https://www.tiktok.com/@accelistedukasiindonesia?_t=8e5VCBHxtib&_r=1" target="_blank">
                            <img src="/img/layouting/tiktok.png" alt="Icon" style={{ width: 40, height: 40 }} className="mr-4"></img>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100092659237819&mibextid=ZbWKwL" target="_blank">
                            <img src="/img/layouting/facebook.png" alt="Icon" style={{ width: 40, height: 40 }} className="mr-4"></img>
                        </a>
                        <a href="https://www.linkedin.com/company/accelist-edukasi-indonesia/" target="_blank">
                            <img src="/img/layouting/linkedin.png" alt="Icon" style={{ width: 40, height: 40 }} className="mr-4"></img>
                        </a>
                    </div>
                    <div className="flex justify-center border-t-2 my-4">
                        <p className="mt-4">Copyright @2023 Accelist Edukasi Indonesia</p>
                    </div>
                </Footer>
                <div
                    className={`fixed bottom-0 right-0 z-40 ${
                        cookieConsent === null ? 'translate-y-[-70px]' : ''
                    }`}
                    >
                    <ScrollToTopButton />
                    <WhatsAppContactOptions />
                </div>
                <CookieBanner updateCookieConsent={updateCookieConsent}/>
                {/* <GoogleAnalytics /> */}
            </Layout>
        </ConfigProvider>
    );
}

export const WithDefaultLayout = (page: React.ReactElement) => <DefaultLayout>{page}</DefaultLayout>;